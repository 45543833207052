<template>
  <v-layout column justify-center fill-height class="multiple-choice">
    <!-- Mission question -->
    <v-flex d-flex v-if="mode != 'social' && instructions">
      <ResizableText :message="instructions" />
    </v-flex>

    <!-- Options -->

    <SlideOpacityTransition>
      <OptionsList :show="showOptions" :options="multipleChoice">
        <template #option="{ option, index }">
          <ChoiceBtn
            :inactive="missionCompleted"
            :correct="isOptionCorrect(index)"
            @click="submit(index)"
          >
            {{ option.firstname + " " + option.lastname }}
          </ChoiceBtn>
        </template>
      </OptionsList>
    </SlideOpacityTransition>

    <!-- Status -->

    <MissionStatus
      v-if="!isPresenterUser"
      :show="showStatus"
      :correct="missionSuccess"
    >
      <template v-if="!isHostLike && statusText">
        {{ statusText }}
      </template>
    </MissionStatus>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import { GameMixin } from "@/mixins"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import Ellipsis from "@/components/GroupTeams/Common/Games/GameCardParts/Ellipsis"
import MissionStatus from "@/components/GroupTeams/Common/Games/GameCardParts/MissionStatus"
import ChoiceBtn from "@/components/GroupTeams/Common/Games/GameCardParts/ChoiceBtn"
import OptionsList from "@/components/GroupTeams/Common/Games/GameCardParts/OptionsList"
import SlideOpacityTransition from "@/components/GroupTeams/Common/Games/GameCardParts/SlideFadeTransition.vue"
import User from "@shared/User"

export default {
  name: "AwardVote",
  components: {
    SlideOpacityTransition,
    OptionsList,
    ChoiceBtn,
    MissionStatus,
    ResizableText,
    Ellipsis
  },
  mixins: [GameMixin],
  props: {
    mode: String,
    mission: Object
  },
  data() {
    return {
      waitingText: "Everyone can vote",
      submited: false
    }
  },
  computed: {
    ...mapGetters([
      "missionCompleted",
      "missionAnswers",
      "gameStatus",
      "onlineUsersArray"
    ]),
    ...mapGetters("auth", ["user", "isHost"]),
    isPresenterUser() {
      return User.isPresenter(this.user)
    },
    isHostLike() {
      return this.isHost || this.isAudit
    },
    instructions() {
      return this.mission?.instructions
    },
    multipleChoice() {
      const value = this.gameStatus?.awardCandidates
      return Array.isArray(value) ? value : []
    },
    statusText() {
      if (this.missionCompleted) {
        return "Submitted"
      }

      if (!this.isScribe && !this.missionCompleted) {
        return this.waitingText
      }

      if (!this.isHost && this.isScribe && !this.missionCompleted) {
        return "Please select"
      }

      return ""
    },
    showStatus() {
      return this.mode === "play" || this.mode === "huddle"
    },
    showOptions() {
      return this.mode === "play" || this.mode === "huddle"
    },
    isScribe() {
      return this.$store.getters.isScribe
    }
  },
  watch: {
    missionCompleted(newValue, oldValue) {
      if (!newValue && oldValue) this.submited = false
    }
  },
  methods: {
    isOptionCorrect(idx) {
      const id = this.multipleChoice[idx]?.id
      return this.missionAnswers.some(obj => obj && obj?.id === id)
    },
    async submit(n) {
      if (this.missionCompleted) return console.log("No rights for submit")
      this.multiAnswer = n
      this.awardID = this.multipleChoice[n].id
      await this.checkAnswer()
    }
  }
}
</script>
