var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "multiple-choice",
      attrs: { column: "", "justify-center": "", "fill-height": "" },
    },
    [
      _vm.mode != "social" && _vm.instructions
        ? _c(
            "v-flex",
            { attrs: { "d-flex": "" } },
            [_c("ResizableText", { attrs: { message: _vm.instructions } })],
            1
          )
        : _vm._e(),
      _c(
        "SlideOpacityTransition",
        [
          _c("OptionsList", {
            attrs: { show: _vm.showOptions, options: _vm.multipleChoice },
            scopedSlots: _vm._u([
              {
                key: "option",
                fn: function (ref) {
                  var option = ref.option
                  var index = ref.index
                  return [
                    _c(
                      "ChoiceBtn",
                      {
                        attrs: {
                          inactive: _vm.missionCompleted,
                          correct: _vm.isOptionCorrect(index),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.submit(index)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(option.firstname + " " + option.lastname) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      !_vm.isPresenterUser
        ? _c(
            "MissionStatus",
            { attrs: { show: _vm.showStatus, correct: _vm.missionSuccess } },
            [
              !_vm.isHostLike && _vm.statusText
                ? [_vm._v(" " + _vm._s(_vm.statusText) + " ")]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }